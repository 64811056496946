import React from 'react'

import { Text, MobileUpperFrame } from 'ui/components'
import { useDimensions } from 'shared/dimensions'
import { styled, theme } from 'ui/styles'
import { YandexMap } from 'app/features'
import { ContactRow } from './contact-row'

export type TContact = {
  title: string
  data: string[]
  telegram?: string
  whatsApp?: string
}

const CONTACTS_DATA: TContact[] = [
  {
    title: 'Сотрудничество',
    data: ['+7 800 101-39-36', 'sales@argo-studio.com'],
  },
  {
    title: 'Работа',
    data: ['+7 999 108-85-45', 'hr@argo-studio.com'],
  },
  { title: 'Адрес', data: ['Ярославль, ул. Свободы, 71А, 4 этаж'] }
]

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 48px;
  height: 100%;
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ContactsList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`

const MapWrapper = styled.div`
  display: flex;
  height: 128px;
  justify-content: center;
  border-radius: 12px;
  margin: 0 0 40px 0;
  background-color: ${theme.palette.light_gray};
  z-index: 2;
`

export const Contacts: React.FC = () => {
  const [mobileMapOpened, setMobileMapOpened] = React.useState(false)

  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  const handleOpenMap = () => {
    setMobileMapOpened(true)
  }
  const handleCloseMap = () => {
    setMobileMapOpened(false)
  }

  const contactsItems = React.useMemo(
    () => (
      <ContactsList>
        {CONTACTS_DATA.map((el, i) => (
          <ContactRow key={i} title={el.title} data={el.data} telegram={el?.telegram} whatsApp={el?.whatsApp} />
        ))}
      </ContactsList>
    ),
    []
  )

  return (
    <Wrapper>
      <ContentWrapper>
        <Text variant="h3" color={theme.palette.dark_blue}>
          Всегда на связи
        </Text>
        {contactsItems}
        {isMobile && (
          <MapWrapper>
            <YandexMap onMapClose={handleCloseMap} onMapClick={handleOpenMap} isOpened={mobileMapOpened} />
          </MapWrapper>
        )}
      </ContentWrapper>

      <MobileUpperFrame openingParameter={mobileMapOpened}>
        <YandexMap onMapClose={handleCloseMap} isOpened={mobileMapOpened} />
      </MobileUpperFrame>
    </Wrapper>
  )
}
